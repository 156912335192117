import { ClassData } from '@models/class-data';
import { TicketSellerCompanyType } from '@models/tickets/ticket-seller-company-type.enum';
import { TicketSellerType } from '@models/tickets/ticket-seller-type.enum';
import { TicketSellerPaymentType } from './ticket-seller-payment-type.enum';

export class TicketSeller {
  id: number;
  createdAt?: Date;
  paymentType: TicketSellerPaymentType;
  type: TicketSellerType;
  companyType?: TicketSellerCompanyType;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  smallBusiness?: boolean;
  vatId?: string;
  vatNumber?: string;
  address: string;
  addressAddition?: string;
  postalCode: string;
  locality: string;
  country?: string;

  stripeConnectedAccountId?: number;
  stripeDetailsSubmitted: boolean;
  stripeChargesEnabled: boolean;

  constructor(json: ClassData<TicketSeller>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.paymentType = json.paymentType;
    this.type = json.type;
    this.companyType = json.companyType;
    this.firstName = json.firstName;
    this.lastName = json.lastName;
    this.companyName = json.companyName;
    this.smallBusiness = json.smallBusiness;
    this.vatId = json.vatId;
    this.vatNumber = json.vatNumber;
    this.address = json.address;
    this.addressAddition = json.addressAddition;
    this.postalCode = json.postalCode;
    this.locality = json.locality;
    this.country = json.country;

    if (json.stripeConnectedAccountId) {
      this.stripeConnectedAccountId = json.stripeConnectedAccountId;
    }

    this.stripeDetailsSubmitted = json.stripeDetailsSubmitted;
    this.stripeChargesEnabled = json.stripeChargesEnabled;
  }

  checkIfValid() {
    if (this.paymentType === TicketSellerPaymentType.STRIPE_CONNECT) {
      return (
        this.stripeConnectedAccountId &&
        this.stripeDetailsSubmitted &&
        this.stripeChargesEnabled
      );
    } else {
      return (
        this.address.trim() !== '' &&
        this.postalCode.trim() !== '' &&
        this.locality.trim() !== '' &&
        ((this.type === TicketSellerType.COMPANY &&
          this.companyName &&
          this.companyName.trim() !== '') ||
          (this.type === TicketSellerType.PERSONAL &&
            this.firstName &&
            this.firstName.trim() !== '' &&
            this.lastName &&
            this.lastName.trim() !== ''))
      );
    }
  }
}
